.App {
  font-family: sans-serif;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul li h2::before {
  content: "👉";
}
.error {
  color: red;
}
